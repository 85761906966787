// // Translateable Accordion Forms
.translatable {
    &:not(.translationmode-active) {
        .language-default {
            max-width: 100%;
            flex: 0 0 100%;
        }
        .language-2 {
            display: none;
        }
    }
}

.show-translation {
    display: block;
}

.hide-translation {
    display: none;
}

// Translation Buttons
button.btn-square.btn-icon-vertical {
    .input-group-prepend.language-label {
        position: absolute; 
        left: -1px; 
        top: -1px;
        height: 100%;

        .input-group-text {
            border-radius: 0;
       }
    }
}

.language-default {
    .input-group-prepend.language-label {
        display: none;
    }
}