// Media Mgmt Page Specific Styles
.infinite-scroll-component__outerdiv {
    width: 100%;
}

.main-media-panel {
    margin-bottom: 120px;
    .mediaselection-perfectscroll {
        max-height: calc(100vh - 180px);

        @media #{$oc-zoomedviewportresolution} {
            max-height: calc(125vh - 180px);
        }
    }

    #scrollableDiv {
        height: calc(105vh);
        overflow: auto;
    }
}

.media-selection-top-buttons-row {
    display: flex;
    justify-content: space-between;
}

.oc-media-masonry {
    display       : flex;
    flex-direction: row;
    flex-wrap     : wrap;
    overflow      : hidden;

    section {
        border-radius: 8px 8px 0 0;
        margin-right : 5px;
        padding      : 0;
        background   : #efefef;
        flex         : 1 1 0px;
        max-width    : 20%;
        min-width    : 15%;
    }

    p {
        margin: 0;
    }
}

.modal {
    .oc-media-masonry section {
        max-width: 25%;
        min-width: 20%;
    }
}

.oc-mediagallery .media-item .custom-checkbox {
    top: -10px;
}

.oc-mediagallery {
    .media-title {
        white-space: nowrap;
        float      : left;
        max-width  : 100%;
        position   : relative;
        font-size  : 0.75rem;
        width      : 100%;

        .description,
        .filetype {
            white-space  : nowrap;
            overflow     : hidden;
            text-overflow: ellipsis;
            display      : inline-block;
        }

        .description {
            max-width   : calc(100% - 30px);
            padding-left: 7px;
        }

        .filetype {

            //position: absolute; right: 7px;
            &:before {
                content: ".";
            }
        }
    }

    // Checkbox Selection
    .media-item {
        position: relative;

        .custom-checkbox {
            position: absolute;
            right   : 3px;
            top     : 0;

            label {
                visibility: hidden;
                width     : 1px;
                height    : 1px;

                &:before,
                &:after {
                    visibility: visible;
                    width     : 1.5rem;
                    height    : 1.5rem;
                }
            }
        }

        &.type-file {
            img {
                padding   : 10px;
                background: #f9f9f9;
            }

            .media-thumbnail {
                .file-extension {
                    right         : 5px;
                    top           : 50%;
                    background    : gray;
                    width         : 50px;
                    padding       : 4px;
                    color         : #fff;
                    font-weight   : bold;
                    position      : absolute;
                    z-index       : 1;
                    text-transform: uppercase;
                }

                &:before {
                    content          : "";
                    width            : 10px;
                    height           : 20px;
                    position         : absolute;
                    right            : 6px;
                    top              : 47%;
                    background       : transparent;
                    -webkit-transform: translateY(-100%);
                    transform        : translateY(-100%);
                    width            : 0px;
                    height           : 0px;
                    -webkit-transform: rotate(262deg);
                    border-style     : solid;
                    border-width     : 2px 0 6px 7px;
                    border-color     : transparent transparent transparent gray;
                }
            }

            &.file-pdf {
                .media-thumbnail {
                    .file-extension {
                        background: #d61d1f;
                    }
                }
            }

            &.file-zip {
                .media-thumbnail {
                    .file-extension {
                        background: #fcdc4a;
                    }
                }
            }

            &.file-rar {
                .media-thumbnail {
                    .file-extension {
                        background: #773b8f;
                    }
                }
            }

            &.file-txt {
                .media-thumbnail {
                    &:after {
                        background: #39509e;
                    }
                }
            }

            &.file-doc,
            &.file-docx {
                .media-thumbnail {
                    .file-extension {
                        background: #4199d4;
                    }
                }
            }

            &.file-xls,
            &.file-xlsx {
                .media-thumbnail {
                    .file-extension {
                        background: #24a455;
                    }
                }
            }

            &.file-ppt {
                .media-thumbnail {
                    .file-extension {
                        background: #ef7a37;
                    }
                }
            }

            &.file-psd {
                .media-thumbnail {
                    .file-extension {
                        background: #30bdeb;
                    }
                }
            }

            &.file-ai {
                .media-thumbnail {
                    .file-extension {
                        background: #f9af19;
                    }
                }
            }

            &.file-indd {
                .media-thumbnail {
                    .file-extension {
                        background: #de3d8f;
                    }
                }
            }

            &.file-psd {
                .media-thumbnail {
                    .file-extension {
                        background: #30bdeb;
                    }
                }
            }

            &.file-aep {
                .media-thumbnail {
                    .file-extension {
                        background: #8f5ba2;
                    }
                }
            }

            &.file-eps {
                .media-thumbnail {
                    .file-extension {
                        background: #3bb27c;
                    }
                }
            }
        }

        &.type-img {
            .file-extension {
                display : none;
                position: absolute;
            }

            img.no-preview {
                padding: 10px;
            }
        }

        .media-thumbnail {
            height: 150px;
            ;
            // min-height: 85px;

            img {
                max-width : 100%;
                width     : 100%;
                height    : 100%;
                object-fit: cover;
            }
        }

        .legend-overlay {
            position      : absolute;
            top           : 100%;
            width         : 99%;
            visibility    : hidden;
            font-size     : 11px;
            background    : rgba(0, 0, 0, 0.5);
            color         : #fff;
            pointer-events: none;

            ul {
                list-style-type: none;
                padding        : 3px;
                margin         : 0;

                .title {
                    &:after {
                        content: ": ";
                    }
                }

                li {
                    margin-bottom : 2px;
                    border-bottom : dotted 1px #fff;
                    padding-bottom: 2px;

                    &:last-child {
                        border-bottom: none;
                    }
                }
            }
        }

        &:hover {
            .legend-overlay {
                transition: all 200ms;
                top       : 20%;
                visibility: visible;
                z-index   : 5;
            }
        }
    }
}

// upload assets modal styling
.assetupload-perfectscroll {
    max-height: 51vh;
}

.single-file {
    display              : -webkit-box;
    display              : -ms-flexbox;
    display              : flex;
    -webkit-box-orient   : horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction   : row;
    flex-direction       : row;
    -webkit-box-align    : center;
    -ms-flex-align       : center;
    align-items          : center;

    .file-upload-details {
        display              : -webkit-box;
        display              : -ms-flexbox;
        display              : flex;
        -webkit-box-orient   : horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction   : column;
        flex-direction       : column;
        width                : 100%;

        .remove-from-list {
            -ms-flex-item-align: baseline;
            align-self         : baseline;
        }
    }
}


// Media Gallery Page / Modal Specifica
.oc-mediagallery {
    &:not(.accordion-gallerysection) {
        .threedotsmenu {
            display: none;
        }

        .input-group-prepend.language-label {
            display: none;
        }
    }
}

// Accordion Specifica
.oc-mediagallery {
    &.accordion-gallerysection {

        .media-thumbnail {
            width     : 50px;
            height    : 50px;
            min-height: 50px;

            &.image-asset {
                -o-object-fit: cover;
                object-fit   : cover;
            }

            &.document-asset {
                -o-object-fit: contain;
                object-fit   : contain;
            }
        }

        .media-item {
            border-bottom : solid #e9ecef 1px;
            padding-bottom: 1rem;
            max-width     : 600px;

            .media-description {
                width: calc(100% - 87px);
            }

            .media-assets-button {
                width: 87px;
            }

            .input-group-prepend.language-label {
                position: absolute;
                left    : 0;
                top     : 0;

                .input-group-text {
                    border-radius: 0;
                }
            }
        }
    }
}

.media-left .media-thumbnail {
    width: 90px;
}

.input-group-prepend+div.oc-mediagallery {
    border : #ced4da solid 1px;
    padding: 2px;
}

.translations-fields .input-group .media-left {
    margin-left: 10px
}


.filters-section {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;

    .custom-input-wrapper{
        width: 50%;
    }

    .custom-control {
        display: flex;
        flex-basis: 100%;
        flex: 1;
    }
}

.media-selection-top-buttons-row {
    display: flex;
    justify-content: space-between;
}

.list-tile-view-switcher{
    display: flex;
    gap: 10px
}