.oc-well {
    background   : $color_bg_lightgray;
    padding      : 0.375rem 0.75rem;
    border-radius: 10px;
}

.img-border {
    border: solid 1px $gray-500;
}

img {
    max-width: 100%;
}

.app-page-title {
    margin: -20px -20px 0px -20px;
}

.o-scroll-y {
    overflow-y: auto;
}

.bradius10 {
    border-radius: 10px;
}
.card-header, .card-title {text-transform: none;}
.p-0-horizontal {
    padding-left : 0;
    padding-right: 0;
}

.p-0-vertical {
    padding-top   : 0;
    padding-bottom: 0;
}

.cursor-pointer {
    cursor: pointer;
}

.align-self-center {
    align-self: center !important;
}

.flex-grow-2 {
    flex-grow: 2 !important;
}

.hideSplitter {
    .handle-bar {
        display: none;
    }
}

.global-alert {
    top       : 70px;
    left      : 280px;
    width     : calc(100% - 280px);
    padding   : 0 10px;
    transition: all 0.4s;
    position  : fixed;
    z-index   : $zindex-fixed;
}

.closed-sidebar {
    .global-alert {
        width     : calc(100% - 80px);
        left      : 80px;
        transition: all 0.4s;
    }
}