@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');

// Bootstrap overwrite
$font-family-sans-serif: "Open Sans",
"Segoe UI",
Roboto,
"Helvetica Neue",
Arial,
"Noto Sans",
sans-serif,
"Apple Color Emoji",
"Segoe UI Emoji",
"Segoe UI Symbol",
"Noto Color Emoji";
$font-family-monospace: SFMono-Regular,
Menlo,
Monaco,
Consolas,
"Liberation Mono",
"Courier New",
monospace;
$font-family-base: $font-family-sans-serif;

$font-size-base: 0.8rem;

$font-size-sm : $font-size-base * .650;
$font-size-smd: $font-size-base * .800;
$font-size-md : $font-size-base * 1.1;
$font-size-lg : $font-size-base * 1.4;
$font-size-xl : $font-size-base * 1.8;

$h1-font-size: $font-size-base * 2.5;
$h2-font-size: $font-size-base * 2;
$h3-font-size: $font-size-base * 1.2;
$h4-font-size: $font-size-base;
$h5-font-size: $font-size-base;
$h6-font-size: $font-size-base;

@mixin font-family($font-weight, $font-style, $font-size, $line-height) {
    font-family: $font-family-base;
    font-style : $font-style;
    font-weight: $font-weight;
    font-size  : $font-size;
    line-height: $line-height;
}

h1,
.h1 {
    @include font-family(bold, normal, $h1-font-size, 30px);
}

h2,
.h2 {
    @include font-family(bold, normal, $h2-font-size, 27px);
}

h3,
.h3 {
    @include font-family(bold, normal, $h3-font-size, 22px);
}

h4,
.h4 {
    @include font-family(bold, normal, $h4-font-size, 22px);
}

h5,
.h5 {
    @include font-family(normal, normal, $h5-font-size, 19px);
}

h6,
.h6 {
    @include font-family(normal, normal, $h6-font-size, 19px);
}

.modal-header,
.modal-header h5 {
    color      : rgba(darken($primary, 40%), .7);
    font-weight: normal;
    font-size  : $font-size-lg;
}

.font-size-base {
    font-size: $font-size-base;
}

.font-size-sm {
    font-size: $font-size-sm;
}

.font-size-smd {
    font-size: $font-size-smd;
}

.font-size-md {
    font-size: $font-size-md;
}

.font-size-lg {
    font-size: $font-size-lg;
}

.font-size-xl {
    font-size: $font-size-xl;
}
.font-weight-medium-bold {font-weight: 600;}