// Admin Module - Styleguide 

// colorpalette
$color_primary  : #2F64A9;
$color_secondary: #96B1D3;
$color_third    : #666666;

$color_heading_bg: #96B1D3;

$color_button             : #2F64A9;
$color_button_bg          : #F5F5FB;
$color_button_hover       : #0B428A;
$color_button_hover_border: #0B428A;
$color_button_hover_bg    : #C3D6EC;

$color_icon      : #2F64A9;
$color_icon_hover: #0B428A;
$color_icon_menu : #0B428A;

$color_font_dark : #131336;
$color_font_light: #8F92A5;
$color_font_white: #FFFFFF;
$color_font_link : #1786BF;

$color_success    : #28A745;
$color_warning    : #FFC107;
$color_danger     : #DC3545;
$color_important  : #007BFF;
$color_information: #17A2B8;

$color_bg_darkgray : #343A40;
$color_bg_gray     : #6C757D;
$color_bg_lightgray: #F5F5FB;
$color_bg_light    : #FDFDFE;
$color_bg_white    : #FFFFFF;

$color_table_thead_bg: #ECEEEF;
$color_table_tbody_bg: #FFFFFF;

.bg_lightgray {
        background-color: $color_bg_lightgray;
}

.bg_lightgray-darken {
        background-color: darken($color_bg_lightgray, 1.5%);
}


////////////////////////////////////////
// Variables overwrite from Bootstrap //
////////////////////////////////////////

// GENERIC

$enable-caret       : true;
$enable-rounded     : true;
$enable-shadows     : false;
$enable-gradients   : false;
$enable-transitions : true;
$enable-grid-classes: true;
$enable-print-styles: false;

$body-bg: #fff;

// Colors

$white   : #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black   : #000;

$grays: ();

$grays: map-merge(("100": $gray-100,
                "200": $gray-200,
                "300": $gray-300,
                "400": $gray-400,
                "500": $gray-500,
                "600": $gray-600,
                "700": $gray-700,
                "800": $gray-800,
                "900": $gray-900),
        $grays);

//--#{$color}
$brand     : $color_primary;
$blue      : $color_important;
$indigo    : #444054;
$purple    : #83588a;
$pink      : #f65ca2;
$red       : $color_danger;
$orange    : #fd7e14;
$yellow    : $color_warning;
$green     : $color_success;
$teal      : $color_information;
$cyan      : #30b1ff;
$blue-light: #eeeeee;

$primary  : $brand;
$secondary: $color_secondary;
$success  : $color_success;
$info     : $color_information;
$warning  : $color_warning;
$danger   : $color_danger;

$light: $color_font_light;
$dark : $color_font_dark;
$focus: $indigo;
$alt  : $purple;

$theme-colors: ();

$theme-colors: map-merge(("primary": $primary,
                "secondary": $secondary,
                "third": $color_third,
                "success": $success,
                "info": $info,
                "warning": $warning,
                "danger": $danger,
                "light": $light,
                "dark": $dark,
                "focus": $focus,
                "alternate": $alt),
        $theme-colors);


$grid-breakpoints: (xs: 0,
        sm: 768px,
        md: 1024px,
        lg: 1200px,
        xl : 1600px);
// Mixin for Special OC Breakpoint between 1200px and 1500px (zoomed out resolution)
$oc-zoomedviewportresolution: "(max-width: 1500px) and (min-width: 1200px) and (min-height: 600px)";