// Fix Dropdown Arrows

.dropright {
    &.show {
        .dropdown-menu {
            &:before {
                left: -5px;
                top : 5px;
            }

            &:after {
                left: -8px;
                top : 6px;
            }
        }
    }
}

// Select List overwrite
.select-input {

    // height: 30vh;
    .rw-select-list {
        .rw-list-option {
            padding-left: 0px;

            .rw-select-list-label {
                .rw-select-list-input {
                    display: none;
                }
            }
        }
    }
}

// Forms overwrite
.form-control {
    &:focus {
        box-shadow  : none;
        border-color: $color_primary;
    }
}

.vh-110 {
    height: 110vh;
}

.cursor-default {
    cursor: default !important;
}

//default scrollbar overwrite
::-webkit-scrollbar {
    width           : 10px;
    background-color: #f2f2f2;
    border-radius   : 10px;
    height          : 10px;
}

::-webkit-scrollbar-track {
    border-radius   : 10px;
    background-color: #f2f2f2;
}

::-webkit-scrollbar-thumb {
    border-radius   : 10px;
    background-color: #CCCED1;
}