.loader-placeholder {
    height: 100%;

    .overlay {
        width: 100%;

        .loader-wrapper {
            z-index         : 3;
            position        : fixed;
            display         : flex;
            width           : 100%;
            height          : 100%;
            align-items     : center;
            justify-content : center;
            background-color: rgba(47, 100, 169, .2);
            z-index: 900000;
        }
    }
}

.pane.vertical {
    .loader-wrapper {
        width: calc(100% - 700px);
        min-width: 100px;
    }
}

.loader-component {
    width          : 100%;
    height         : 100%;
    display        : flex;
    justify-content: center;
    align-items    : center;
}
