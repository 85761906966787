.threedotsmenu {
    position: absolute; 
    right: 0; 
    top:0;    
    button.dropdown-toggle {
        font-size: $font-size-lg; 
        margin-right: 0 !important;
        color: $primary;
        &:after {
            content: none;
        }
    }
}
.rstcustom__rowWrapper {
    > div {height: 100%;}
}

.rstcustom__rowWrapper .rstcustom__rowToolbar {
    .threedotsmenu {
       // width: 50px; height: 60px; text-align: right;
       top: -6px;
        .dropdown > button {
            //width: 50px; height: 60px;
            margin-bottom: 0; 
            // padding-left: 50px; 
            > * {margin-top: 9px;}
            }
        .dropdown-menu {
            margin-top: -7px !important;
        }
        .dropdown.show {
            .dropdown-menu {
                &:before {content: none;}
                &:after {content: none;}
            }
        }
    }
}