
// View Mode VS. Edit Mode (if draggable --> not static --> links must be disabled in order not to disturb drag&drop function 
.oc-grid-tile-keyframe {
    &.static {}
    &.react-draggable, &.react-resizable {
        a.tile-header, .tile-header a, a.tile-header-link, .tile-content a, .tile-content, .threedotsmenu {
            pointer-events: none; 
            filter: blur(2.5px);
            -webkit-filter: blur(2.5px);       
        }
        cursor: move; /* fallback if grab cursor is unsupported */
        cursor: grab;
        cursor: -moz-grab;
        cursor: -webkit-grab;
        border: solid $color_button 2px;
    }

    // Drag and Drop Handle
    &.react-grid-item > .react-resizable-handle {
        width: 40px; height: 40px;
        &:after {
            width: 20px;
            height: 20px;
            border-right: 4px solid $color_button;
            border-bottom: 4px solid $color_button;
        }
    }

}
 
// Button Tiles non-draggable Edit mode
.edit-mode-tile-btn {
    button:first-child {
        pointer-events: none; 
            filter: blur(1.5px);
            -webkit-filter: blur(1.5px); 
    }
}
.dashboard-tile-btn {
    position: relative;
}


// Container Height Definitions:
#dragndrop-dashboard {
  //  height: calc(100% - 65px - 200px);
    .scrollbar-container {        
        &.section-generalinfo {
            height: calc(100vh - 250px);
            @media #{$oc-zoomedviewportresolution} {  
                height: 100vh;
            }
       // &.section-statistics {
         //   height: 400px;
       // }
    }     
}
}

$tile-border-radius: 8px;

// Tile-Size specific Appearence
.oc-grid-tile-keyframe {
   // border: solid black 1px; 
   background: #fff;
   border-radius: $tile-border-radius !important;   
        &[w="1"][h="1"] {
            .hiddenContentOn1x1 {
                .tile-content {visibility: hidden; display: none;}
                .tile-header {
                    justify-items: stretch; height: 100%; align-items: center; width: 100%;
                    .title {display: none;}
                    .icon {
                        font-size: 3em;
                        width: 100%; display: flex; text-align: center; flex-flow: column;
                        > div {
                            margin: 0 !important; padding: 0 !important;
                        }
                        .fa-icon {
                            width: 2em; height: 2em;
                        }
                        .badge {font-size: 12px; top: -13px; right: 11px;}
                    }
                }
            }
            .hiddenTitleOn1x1 {
                .tile-header {visibility: hidden; display: none;}
            }
    }
}
 

.responsive-gridtile-section {

}


// Tile-Content-Height
.oc-grid-tile {
    .tile-content {
        .scrollbar-container {
         //   height: 40px;
         width: 100%;
        }

    }
}

// Above Tiles
.dashboard-tile-btn {
    .oc-grid-tile {
        padding: 0; 
    }
    button:first-child {
        padding: 12px 25px;border-radius: 8px !important; background: #fff; background-color: #fff;background-color: none;
        &:hover, &:focus, &:active {background: #fff !important; color: $primary !important; background-color: #fff !important;}
    }
}



// Inner-Tile-Styling
.oc-grid-tile {
    // General Tile Styling
    padding: 10px;
    align-items: stretch;
    height: 100%;
    max-height: 100%;  
    .tile-header {
        display: flex; white-space: nowrap; font-weight: bold; font-size: $font-size-xl; 
        .icon {
            //float: left; display: inline-block; 
        }
        .title {
            overflow-x: hidden; overflow-y: visible; text-overflow: ellipsis;
            font-weight: normal; letter-spacing: -1px;
        }
    }
    .tile-content {
        display: flex;
        width: 100%;
        /* align-self: initial; */
        /* height: 100%; */
        /* max-height: 100%; */
        align-items: stretch;
        max-height: calc(100% - 40px);
        .card {width: 100%;}
    }
    .badge {
        top: inherit; top: -7px; right: -7px;
    }





    // Tile Specific Styling
    .oc-tile {
        display: flex;
        flex-flow: column;
        align-items: stretch;
        max-height: 100%;
        height: 100%;
        color: $gray-800;
        .unpin-current-tile-btn {
            position: absolute; right: 0; top: 0; border-right: none; border-top: none; border-radius: 0 5px 0 0 !important;
            z-index: 99;
            &:hover {
                background-color: #fff; border-left: solid $color_button 1px !important; border-bottom: solid $color_button 1px !important;
                    &:before {
                        content: attr(tooltip-title);
                        background: black;
                        color: #fff;
                        font-size: 12px;
                        position: absolute;
                        top: 42px;
                        right: 0;
                        padding: 0 5px;
                        z-index: 9999;                    }
                
            }
        }
        ul, ol {
            
            li {line-height: 1em; margin-bottom: 0.5em;}
        }
        a {color: $gray-800;}
        .threedotsmenu {
            a {color: $primary;}
            button.dropdown-toggle {background: none; border: none; color: $primary !important;}
        }
        .tile-header, a.tile-header, a.tile-header-link {
            color: $black; 
            display: flex;
            width: 100%;
        }
       /* a.tile-header-link {
            &:hover {
                &:before {
                    content: " " attr(title);
                    background: black;
                    color: #fff;
                    font-size: 12px;
                    position: absolute;
                    top: 42px;
                    right: 0;
                    padding: 0 5px;
                    z-index: 9999;
                }
            }
        }*/
        &.oc-threedotmenutile {
            .tile-header-link {width: calc(100% - 31px);}
            .threedotsmenu {
                margin-top: 8px; margin-right: 8px;
                .dropdown-menu.show {z-index: 888;}
            }
        }
        &.oc-link-list-tile {
            // border: solid red 1px;
            ul, ol {
                padding-left: 27px;
            }
        }
        &.oc-file-list-tile {
            // border: solid red 1px;
            ul, ol {
                padding-left: 0;
                li {
                    list-style-type: none;
                    a {
                        padding-top: 2px; padding-bottom: 2px; display: flex; align-items: center; position: relative;
                        &:before {
                            content: "File";
                            position: absolute;
                            width: 19px;
                            height: 20px;
                            background: #444; color: #fff;
                            top: 1.2em;
                            left: 0; width: auto;
                            height: 1.2em;
                            display: block;
                            padding: 0 2px;
                            font-size: 0.8em;
                        }
                    }
                    .fa-icon {color: $black; margin-right: 2px; width: 2em; height: 2em; }
                    &.file-pdf {.fa-icon {color: #d61d1f;}a:before {content:"PDF"} } 
                    &.file-zip {.fa-icon {color: #fcdc4a;}a:before {content:"ZIP"}} 
                    &.file-rar {.fa-icon {color:  #773b8f;}a:before {content:"RAR"}} 
                    &.file-txt {.fa-icon {color: #39509e;}a:before {content:"TXT"}} 
                    &.file-doc, &.file-docx  {.fa-icon {color:  #4199d4;}a:before {content:"DOC"}} 
                    &.file-xls, &.file-xlsx  {.fa-icon {color: #24a455;}a:before {content:"XLS"}} 
                    &.file-ppt, &.file-pps  {.fa-icon {color:  #ef7a37;}a:before {content:"PPT"}} 
                    &.file-psd {.fa-icon {color:  #30bdeb;}a:before {content:"PSD"}} 
                    &.file-ai {.fa-icon {color:  #f9af19;}a:before {content:"AI"}} 
                    &.file-indd {.fa-icon {color:  #de3d8f;}a:before {content:"INDD"}} 
                    &.file-psd {.fa-icon {color:  #30bdeb;}a:before {content:"PSD"}} 
                    &.file-aep {.fa-icon {color: #8f5ba2;}a:before {content:"AEP"}} 
                    &.file-eps {.fa-icon {color:  #3bb27c;}a:before {content:"EPS"}}
                }
            }
        }
        &.oc-task-list-tile {
            ul {padding-left: 0;}
        }
    }
    
}

