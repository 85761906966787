﻿.oc-hovertooltip {
    position: relative;

    .oc-hovertooltip-icon {
        margin-left: 10px;
    }

    &:hover {
        &:before {
            content       : attr(tooltip-title);
            background    : black;
            color         : #fff;
            font-size     : 12px;
            position      : absolute;
            top           : 28px;
            right         : 0;
            text-align    : center;
            min-width     : 70px;
            padding       : 3px 5px;
            max-width     : 150px;
            width         : auto;
            z-index       : 9999;
            pointer-events: none;
            border-radius : 4px;
        }
    }

    &.button-tooltip-indicator {
        &:hover {
            &:before {
                top    : 40px;
                padding: 5px;
            }
        }
    }
}