// Font Size Placeholders
::-webkit-input-placeholder {
  font-size     : x-small;
  vertical-align: top;
  line-height   : 10px;
}

::-moz-placeholder {
  font-size     : x-small;
  vertical-align: top;
  line-height   : 10px;
}

:-ms-input-placeholder {
  font-size     : x-small;
  vertical-align: top;
  line-height   : 10px;
}

:-moz-placeholder {
  font-size     : x-small;
  vertical-align: top;
  line-height   : 10px;
}

// Bootstrap Overwrites
.form-control {
  padding  : 0.375rem 0.175rem;
  font-size: 0.8rem;

  &.is-invalid {
    background-image: unset;
    padding-right   : none;
  }
}

// Required
label.label-mandatory {
  &:after {
    content     : "*";
    padding-left: 5px;
    font-weight : bold;
    color       : $color_danger;
  }
}

.input-group>.form-control:not(:last-child),
.input-group>.custom-select:not(:last-child) {
  border-top-right-radius   : 0.25em; // TODO VARIABLES
  border-bottom-right-radius: 0.25em; // TODO VARIABLES
}

// Datepicker
.oc-date-time-picker {
  .react-datepicker-wrapper .react-datepicker__input-container input {
    cursor: pointer;
  }

  .react-datepicker-popper {
    margin-bottom: 20px;
    z-index      : 1000;

    @media screen and (max-width: 1500px) {
      transform: translate3d(212px, -130px, 0px) !important;
    }

    &:before {
      display      : block;
      position     : relative;
      left         : -8px;
      top          : 140px;
      content      : '';
      width        : 0;
      height       : 0;
      border-top   : 8px solid transparent;
      border-bottom: 8px solid transparent;
      border-right : 8px solid #dee2e6;
    }
  }

  .react-datepicker {
    width          : 345px;
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : stretch;

    .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
      right: 130px;
    }

    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
      width      : 25px;
      line-height: 25px;
      margin     : 2px;
    }

    .react-datepicker__month-container {
      margin-left: 2px;
    }

    .react-datepicker__time-container {
      width: unset;

      .react-datepicker__header--time {
        padding-bottom: 10px;
      }
    }
  }
}

.input-group-text {
  background: inherit;
}

// Multivalue fields translation area
.language-default {
  .input-group {
    .form-control {
      border-top-left-radius   : 0.25em; // Todo: VARIABLES!
      border-bottom-left-radius: 0.25em; // Todo: VARIABLES!
    }
  }
}

// Translation labels
.input-group-prepend {
  .input-group-text {
    font-size       : small;
    font-weight     : bold;
    background-color: $color_button_bg;
    min-width       : 45px;
  }
}

.language-2 {
  .input-group>.input-group-prepend .input-group-text.language-2-prepend {
    border-top-left-radius   : 0.25em; // Todo: VARIABLES!
    border-bottom-left-radius: 0.25em; // Todo: VARIABLES!

    +.form-control {
      border-top-left-radius   : 0;
      border-bottom-left-radius: 0;
      border-left              : 0;
    }
  }
}

.jodit_container {
  width   : calc(100% - 50px) !important;
  position: inherit;
}

.jodit-container.jodit.jodit_theme_default.jodit-wysiwyg_mode {
  max-width: calc(100% - 82px) !important;
}

.rw-widget-input,
.rw-filter-input {
  box-shadow: none;
}

// Global Search
#global-search-submit-btn {
  position: absolute;
  right   : 0;
}

// Search with inside button
.typeahead-search-innersubmit {
  .searchinner-submit {
    position: absolute;
    right   : 10px;
  }
}

// error messages for radio buttons and checkboxes

.validation-done {
  .valid-feedback {
    display: block;
    color  : $color_success;
  }

  .invalid-feedback {
    display: block;
    color  : $color_danger;
  }
}

.was-validated .form-control:valid:focus,
.form-control.is-valid:focus,
.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
  box-shadow: none;
}

.was-validated .form-control:valid,
.form-control.is-valid {
  background-image: unset;
}

// checkboxes
.custom-checkbox {
  align-items: center;
}

.card-body {
  .invalid-feedback {
    display: block !important;
  }
}

.warning-input {
  background-color: rgba(250, 210, 1, 0.15);
  transition      : background-color, linear, .3s;
}


h3.label-mandatory:after,
p.label-mandatory:after {
  content     : "*";
  padding-left: 5px;
  font-weight : bold;
  color       : $color_danger;
}

.invalid-datepicker{
  display: block;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #DC3545;
}

.field-datePicker {
  .invalid-feedback{
    display: block;
  }
}