.accordion {
    &.accordion-wrapper {
        border-radius: 0;
        border       : none;
        height       : 100%;
        overflow     : auto;

        .card-body {
            min-height: 280px;
        }

        >.card {
            >.card-header {
                border-bottom: 1px solid $primary;
                padding      : 8px 10px;
                margin-bottom: 0px;

                .accordion-entry {
                    font-size: $font-size-base;
                    color    : $color_font_dark;
                }

                button:first-child {
                    &:before {
                        content      : "\25b7";
                        padding-top  : 2px;
                        float        : left;
                        padding-right: 10px;
                    }

                    &[aria-expanded="true"] {
                        &:before {
                            content: "\25bc";
                        }
                    }
                }
            }

            .collapse.show {
                background   : $color_bg_lightgray;
                border-bottom: none;
                transition   : height linear .5s;
            }

            &:last-child {
                margin-bottom: 25px;
            }
        }

        .form-group {
            .media-item {
                background-color: rgba(245, 245, 251, .8);
            }

            input:disabled {
                cursor: default;
            }
        }

    }

    &.dropdown-section {
        >.card {
            background-color: $color_bg_lightgray;

            >.card-header {
                background-color: $color_bg_lightgray;
                border-color    : $color_secondary;

                .accordion-action-btns {
                    .btn {
                        &::before {
                            content: none;
                        }
                    }
                }

                .just-entry.btn {
                    &::before {
                        content: none;
                    }
                }
            }
        }
    }
}

// Switcher in accordeon
.select-column-modal {
    max-height: 65vh;

    .accordion-entry {
        display        : flex;
        flex-direction : row;
        justify-content: space-between;
    }
}


.fade {
    @include transition($transition-fade);

    &:not(.show) {
        opacity: 0;
    }
}

.collapse {
    &:not(.show) {
        display: none;
    }
}

.collapsing {
    position  : relative;
    height    : 0;
    overflow  : hidden;
    transition: height .5s ease;
}