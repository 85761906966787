// Modal + Sticky header
.modalinner-sticky-header {
    background: #fff; 

   .sticky-inner-wrapper {
       margin-top: 44px; 
       background: #fff;
       div {
           box-shadow: none;
        }
   }
}

// Handle bar inside a modal
.handle-bar {
    background: #eeeff0 !important;
}

.modal {
   .splitter.vertical {
       .pane {
           padding: 0 10px;
       }

       .handle-bar.vertical {
           + .pane {
               margin-left: 10px;
               overflow-y: scroll;
               overflow-x: hidden;
           }
       }
   }  
}

.modal {
   .modal-body {
       .app-inner-layout .app-inner-layout__wrapper {
           min-height: 100px; 
           max-height: calc(75vh - 2rem);
        }
   }
}

// max height for the element to take full avaliable height 
// for the content without oosing readability 

.modal-xxl {
    max-width: 80vw;
}


@media #{$oc-zoomedviewportresolution} {      
    .modal-backdrop {
        height: 125vh; 
        width: 125vw;
    }
    .modal-lg, .modal-xl {
        max-width: 80vw;
        max-height: 80vh;
    }
  }

.modal-header, .modal-header h5 {word-wrap: break-word; word-break: break-all;}