.header-tab {
  display            : flex;
  align-items        : center;
  border-bottom-width: 1px;
  padding-top        : 0;
  padding-bottom     : 0;
  height             : 3.5rem;
  width              : 100%;

  &>.nav {
    width: 100%;

    .nav-item {
      position   : relative;
      height     : 100%;
      display    : flex;
      align-items: center;
      width      : 100%;
    }

    .nav-link {
      text-transform: none;
      width         : 100%;
      display       : block;
      color         : $gray-700;
      width         : 100%;
      margin-bottom : -20px;
      border-bottom : 1px solid;

      &::before {
        content: '';
        @include border-radius(15px);
        background: $primary;
        transition: all .2s;
        height    : 2px;
        width     : 100%;
        position  : absolute;
        left      : 0;
        bottom    : -15px;
        opacity   : 0;
      }

      &:hover {
        color: $primary;
      }

      &.active {
        color        : $primary;
        font-weight  : bold;
        border-bottom: 0px;

        &::before {
          opacity: 1;
        }
      }
    }
  }

  &.header-tab-animation {
    .nav .nav-link {
      &::before {
        transform: scale(0);
        opacity  : 1;
        width    : 100%;
      }

      &.active,
      &:hover {
        &::before {
          transform: scale(1);
        }
      }
    }
  }

  .acc-action-buttons {
    position: absolute;
    right   : 25px;
  }
}

.fade:not(.show) {
  display: none;
}

.expandable-section .tab-body {
  height    : 0px;
  transition: height 1s ease-out;

  &.expanded {
    height    : auto;
    transition: height 1s ease-in;

  }
}

.show-associations {
  max-height: inherit;
  transition: max-height 1s ease-out;
}

.hide-associations {
  max-height: 0;
  transition: max-height 1s ease-in;
}