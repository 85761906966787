
.perfectscrollbar-shadow-inner.default-visible-handle {
    padding-right: 13px;
    ~ .ps__rail-y {background: #f9f9f9; width: 10px;}
}

// .scrollbar-container {border-bottom: solid #423f3f 3px;}
.scrollbar-container:after { 
 /*   background-image: radial-gradient(at 50% 0, silver -11%, silver -50%, transparent 78%);
    bottom: 0;
    position: absolute;
    bottom: 10px;
    overflow: visible;
    content: "adf";
    width: 100%;
    pointer-events: none;
    transform: rotate(180deg);
    bottom: 0;*/ 
}
.ps__rail-y {opacity: 0.5;}

