.ReactTable {
    border: none;

    .rt-thead {
        &.-header {
            background: $color_table_thead_bg;

            .rt-th.rt-resizable-header {
                text-align: left;
                color: $color_font_dark;
            }
        }

        .rt-th.-sort-desc,
        .rt-td.-sort-desc {
            box-shadow: inset 0 -1px 0 0 $color_primary;
        }

        .rt-th.-sort-asc,
        .rt-td.-sort-asc {
            box-shadow: inset 0 1px 0 0 $color_primary;
        }
    }

    .rt-tr-group {
        background: $color_table_tbody_bg;

        &:hover {
            background: $color_bg_lightgray;
        }
    }

    .rt-th,
    .rt-td {
        white-space: normal;
        word-break: break-all
    }
}

.ReactTable.search-results-table {

    .rt-thead .rt-th:first-child,
    .rt-tbody .rt-td:first-child {
        max-width: 60px;
    }

    .rt-thead .rt-th:nth-child(5),
    .rt-tbody .rt-td:nth-child(5) {
        max-width: 80px;
    }
}

.ReactTable.translation-table {

    .rt-thead .rt-th:nth-child(4),
    .rt-tbody .rt-td:nth-child(4) {
        max-width: 120px;
    }

    .rt-thead .rt-th:nth-child(5),
    .rt-tbody .rt-td:nth-child(5) {
        max-width: 150px;
    }
}

.ReactTable.associations-group-table {

    .rt-thead .rt-th:nth-child(3),
    .rt-tbody .rt-td:nth-child(3) {
        max-width: 70px;
    }

    .rt-thead .rt-th:nth-child(4),
    .rt-tbody .rt-td:nth-child(4) {
        max-width: 110px;
    }

    .rt-thead .rt-th:nth-child(5),
    .rt-tbody .rt-td:nth-child(5) {
        max-width: 110px;
    }

    .rt-thead .rt-th:nth-child(6),
    .rt-tbody .rt-td:nth-child(6) {
        max-width: 120px;
    }

    .association-element-link.inactive {
        color: $gray-500;
        font-style: italic;
    }
}

.ReactTable.export-table {

    .rt-thead .rt-th:nth-child(4),
    .rt-tbody .rt-td:nth-child(4) {
        max-width: 120px;
    }


    .rt-thead .rt-th:nth-child(5),
    .rt-tbody .rt-td:nth-child(5) {
        max-width: 150px;
    }
}