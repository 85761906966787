.btn-icon-wrapper {
    font-weight: bold;
}

.btn-icon .btn-icon-wrapper {
    transition: none;
}

.btn-icon .btn-icon-wrapper.btn-icon-no-label {
    margin-right: 0px;
}

.btn {
    border-radius: 0 !important;
    &:disabled {pointer-events: none;}
}

.btn-primary {
    background: $color_button;
    color: $color_font_white;
    border-color: $color_button;

    &:not(:disabled):hover {
        background: $color_button_hover; 
        border-color: $color_button_hover;
    }
}

.btn-secondary {
    background: $color_button_bg; 
    color: $color_button; 
    border-color: $color_button;

    &:not(:disabled):hover {
        border-color: $color_button_hover_border; 
        background: lighten($color_button, 90%); 
        color: $color_button_hover_border; 
    }

    &:disabled {
        color: silver; 
        background-color: transparent; 
        border-color: silver;
    }
}

.btn-tertiary {
    background: $color_button_bg; 
    color: $color_third; 
    border-color: $color_third;

    &:not(:disabled):hover {
        background: lighten($color_button, 90%); 
        border-color: $color_font_dark; 
        color: $color_font_dark;
    }
}
