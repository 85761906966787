.tree-view {
    display       : flex;
    flex-direction: column;

    .tree-section {
        flex       : 0 0 auto;
        margin-left: -10px;
        width      : calc(100% + 20px);

        .tree-search {
            display        : flex;
            flex-direction : row;
            align-items    : center;
            align-content  : space-between;
            justify-content: stretch;

            .treesearch-buttons {
                width          : 49px;
                margin-left    : 10px;
                display        : flex;
                align-content  : space-between;
                justify-content: space-between;
            }
        }
    }

    .rstcustom__rowWrapper {

        &:hover,
        &:active,
        &:focus {
            opacity: 1 !important;
            cursor : pointer;

            .tree-item-menu {
                display: block;
            }

            .tree-item-info{
                display: block;
            }

            .rstcustom__row {
                background-color: $color_button_bg;
                width           : 100%;
            }
        }

        .rstcustom__row.inactive {
            color: $gray-500;
            font-style: italic;
        }
    }

    .tree-item-menu {
        display: none;

        position        : absolute;
        left            : calc(100% - 55px);
        width           : 55px;
        top             : -8px;
        background-color: rgba($color: #fff, $alpha: 0.65);

        a.tree-item-search {
            padding: 7px;
            float  : left;
        }

        span.tree-item-info {
            padding: 7px;
            float  : left;
        }

        a.tree-item-search,
        button.dropdown-toggle {
            color: $color_primary;
        }

    }
    
    .tree-item-info{
        display: none;
        position: absolute;
        left: calc(100% - 50px);
        width: 13px;
        top: 5px;
    }
    
    p {
        font-size: $font-size-smd;
    }

    .tree-component {
        flex    : 1 0 50%;
        margin  : 0 -20px 0 -10px;
        overflow: auto;
        height  : 100%;

        @media screen and (min-width: 1501px) {
            min-height: calc(100vh - 260px);
        }

        @media screen and (max-width: 1500px) and (min-width: 1200px) {
            min-height: calc(100vh - 100px);
        }

        @media screen and (max-width: 1199px) {
            min-height: calc(100vh - 250px);
        }

        &.tree-checkboxes {
            height    : 100%;
            min-height: 350px;
            overflow-y: hidden;
        }

        .tree-layout {
            height     : 100%;
            width      : calc(100% - 1px);
            font-size  : $font-size-base;
            line-height: 25px;

            .rstcustom__lineBlock {

                ~.rstcustom__row {
                    justify-content: stretch;
                    width          : 100%;

                    .rstcustom__rowContents {
                        .rstcustom__rowLabel {

                            span {
                                line-height: 1.2em;
                                padding    : 2.5px 0;
                                display    : block;
                                max-width  : 100%;
                            }
                        }

                    }
                }
            }
        }
    }
}
.tree-info-tooltip {
    text-align: left; width: 220px; max-width: 300px;
}
.ReactVirtualized__Grid__innerScrollContainer {

    .rstcustom__collapseButton::after,
    .rstcustom__expandButton::after {
        border-top-color  : $color_bg_gray;
        border-left-width : 5px;
        border-right-width: 7px;
        transform-origin  : 5px 5px;
    }

    .rstcustom__rowWrapper {
        .rstcustom__rowSearchFocus {
            box-shadow : none;
            font-weight: bold;
            color      : $color_primary;
        }

        .rstcustom__rowSearchMatch {
            box-shadow: none;
            color     : $color_primary;
        }

        .rstcustom__rowToolbar {
            cursor: pointer;
        }

        .rstcustom__rowContents {
            display        : flex;
            flex           : 1 1 auto;
            flex-direction : row;
            justify-content: flex-start;
        }
    }
}
.rstcustom__rowContents {justify-content: flex-start !important;}
.rst__virtualScrollOverride {
    overflow-x: visible !important;
}


.assignment-view {
    max-height   : calc(50vh - 170px);
    margin-bottom: 20px;
}

.assignment-selectarea {
    max-height: calc(50vh - 170px);
    height    : calc(50vh - 170px);
}

.product-catalog-view {
    height    : 70vh;
    max-height: 70vh;
}

.tree-scroll-area-lg{
    height: 100vh;

    @media screen and (min-width: 1501px) {
        height: calc(100vh - 260px);
    }

    @media screen and (max-width: 1500px) and (min-width: 1200px) {
        height: calc(100vh - 100px);
    }

    @media screen and (max-width: 1199px) {
        height: calc(100vh - 250px);
    }
}