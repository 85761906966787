.app-container {
  .app-header__logo {
    .logo-src {
      height: 40px;
      width : 200px;
    }

    .header__pane {
      top       : 60px;
      left      : 250px;
      transition: all .3s ease;

      &:hover {
        transform    : all 0.2s;
        padding-right: 5px;
        left         : 245px;

        .hamburger-box {
          opacity: 0.5;
        }
      }
    }
  }

  &.closed-sidebar {
    &:not(.closed-sidebar-mobile) {
      .app-header {
        .app-header__logo {
          width: 280px;

          .logo-src {
            height    : 40px;
            width     : 200px;
            background: url('~assets/utils/images/logo.png');
            display   : block;
          }

          .header__pane {
            transition: all .3s ease;
            top       : 60px;
            left      : 0;
            height    : 100vh;

            &:hover {
              padding-left: 5px;
              transform   : padding-left 0.2s;
            }
          }
        }
      }
    }
  }
}




.hamburger {
  height      : 100vh;
  padding-left: 20px;

  .hamburger-box {
    width        : 0;
    height       : 0;
    border-top   : 30px solid transparent;
    border-right : 10px solid $color_bg_gray;
    border-bottom: 35px solid transparent;
    opacity      : 0.3;

    .hamburger-inner {
      display: none;
    }
  }

  &.is-active {
    padding-right: 20px;
    padding-left : 0;

    .hamburger-box {
      transform: rotate(180deg);
    }
  }

}