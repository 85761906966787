.vh100-fixedtopmenu {
    height: calc(100vh - 60px);
    max-height: calc(100vh - 60px);
}

.modal-body .vh100-fixedtopmenu {
    height: calc(100vh - 200px);
    max-height: calc(100vh - 200px);
}

.he-100minus-cardpadding {
    height: calc(100% - 1.25rem - 1.25rem);
}

.modal .modal-yscroller-fullheight-nostickytitle {
    max-height: calc(100vh - 300px);
}

.modal .modal-yscroller-fullheight-withstickytitle {
    max-height: calc(100vh - 340px);
}

// Template overwrites
.app-inner-layout .app-inner-layout__wrapper {
    min-height: 200px;
    overflow-y: hidden; //border: solid red 2px;

    .app-inner-layout__content {
        //border: solid 2px blue;// overflow-y: scroll;
    }
}

/*
.fixed-sidebar .app-main .app-main__outer {
    max-height: calc(100vh - 60px); border: solid red 1px; overflow-y: hidden;
    .app-main__inner {
        max-height: 100%; overflow-y: scroll;
    }
} */


.fixed-footer {
    .app-main .app-main__outer {
        padding-bottom: 0;
        padding-top: 0;
    }
}

.app-sidebar {
    border-right: 1px solid #f2f2f2;
}

// HTML Editor Fullsize
html.jodit_fullsize_box {
    width: 100% !important;
    min-width: 100%;
}



@media #{$oc-zoomedviewportresolution} {

    // General View
    html {
        zoom: 0.8;
    }

    .app-sidebar,
    .fixed-sidebar .app-sidebar,
    .app-main__outer {
        height: 125vh;
    }

    .vh100-fixedtopmenu {
        height: calc(125vh - 60px);
        max-height: calc(125vh - 60px);
    }

    .app-container {
        max-height: 125vh;
        overflow: hidden;
    }

    .h-100 {
        height: 125vh !important;
    }
}